import React from "react";
import { Switch } from "react-router-dom";
import ProtectedRoute from "./ProtectedRoute";
import { PAGES } from "./pages";

const PageESSDashboard = React.lazy(() => import("../pages/ess/dashboard"));
const PageESSPayHistory = React.lazy(() => import("../pages/ess/pay-history"));
const PageESSPayHistoryDetail = React.lazy(() => import("../pages/ess/pay-history/[id]"));
const PageESSProfile = React.lazy(() => import("../pages/ess/profile"));
const PageESSDocument = React.lazy(() => import("../pages/ess/documents"));
const PageESSTaxWithholdings = React.lazy(() => import("../pages/ess/tax-withholdings"));
const PageESSDirectDeposit = React.lazy(() => import("../pages/ess/direct-deposit"));
const PageESSTimeKeeping = React.lazy(() => import("../pages/ess/time-keeping"));
const PageESSSetup = React.lazy(() => import("../pages/ess/setup"));

export const EssRoutes = ({}) => {
  return (
    <Switch>
      <ProtectedRoute
        path={PAGES.essPayHistoryDetail.path}
        title={PAGES.essPayHistoryDetail.title}
        permission
        component={PageESSPayHistoryDetail}
      />
      <ProtectedRoute
        path={PAGES.essDashboard.path}
        title={PAGES.essDashboard.title}
        permission
        component={PageESSDashboard}
      />
      <ProtectedRoute
        path={PAGES.essPayHistory.path}
        title={PAGES.essPayHistory.title}
        permission
        component={PageESSPayHistory}
      />
      <ProtectedRoute
        path={`${PAGES.essProfile.path}/:section`}
        title={PAGES.essProfile.title}
        permission
        component={PageESSProfile}
      />
      <ProtectedRoute
        path={PAGES.essProfile.path}
        title={PAGES.essProfile.title}
        permission
        component={PageESSProfile}
      />
      <ProtectedRoute
        path={`${PAGES.essDocuments.path}/:year`}
        title={PAGES.essDocuments.title}
        permission
        component={PageESSDocument}
      />
      <ProtectedRoute
        path={PAGES.essDocuments.path}
        title={PAGES.essDocuments.title}
        permission
        component={PageESSDocument}
      />
      <ProtectedRoute
        path={PAGES.essTaxWithholdings.path}
        title={PAGES.essTaxWithholdings.title}
        permission
        component={PageESSTaxWithholdings}
      />
      <ProtectedRoute
        path={PAGES.essDirectDeposit.path}
        title={PAGES.essDirectDeposit.title}
        permission
        component={PageESSDirectDeposit}
      />
      <ProtectedRoute
        path={PAGES.essTimeKeeping.path}
        title={PAGES.essTimeKeeping.title}
        permission
        component={PageESSTimeKeeping}
      />
      <ProtectedRoute
        path={PAGES.essSetup.path}
        title={PAGES.essSetup.title}
        permission
        component={PageESSSetup}
      />
    </Switch>
  );
};
